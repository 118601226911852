// external imports
import React, { Fragment } from "react"
import moment from "moment"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import styled, { css } from "styled-components"

// helpers
import { up, addResponsivity } from "../lib/styles"
import {
  extractNodes,
  extractNodesFromData,
  extractNodesFromDataApplyLangs,
} from "../lib/helpers"
import { applyLang, applyLangSingle } from "../lib/intl"
import injectIntl from "../lib/intl/injectIntl"

// components
import {
  SEO,
  Title,
  Gap,
  Row,
  Col,
  Link,
  Text,
  RH,
  WaveImage,
  Listing,
  ContactFormSection,
  Button,
  ResponsiveImage,
} from "../components"
import T from "../lib/intl/T"
import PostsListing from "../components/PostsListing"

// assets
import claimPath1 from "../data/images/foundation-claim-1.svg"
import claimPath2 from "../data/images/foundation-claim-2.svg"
import claimPath3 from "../data/images/foundation-claim-3.svg"
import calendarPath from "../data/images/icon-calendar.svg"

// custom styled components

let savedState = null

class Foundation extends React.Component {
  state = {
    limit: 10,
  }

  componentDidMount() {
    if (savedState) {
      this.setState({ ...savedState })
    }
  }

  componentWillUnmount() {
    savedState = { ...this.state }
  }

  render() {
    const {
      data,
      location: { pathname },
      intl: { language, t },
    } = this.props
    const { limit } = this.state

    const { posts, introImage, events } = extractNodesFromDataApplyLangs(
      data,
      language
    )

    const firstPosts = posts.slice(0, 6)
    const afterEventPosts = posts.slice(6, limit)

    const claims = [
      {
        text: t("Pomáháme řešit problémy těm, kteří si to nemohou dovolit"),
        imgPath: claimPath1,
      },
      {
        text: t("Plastická chirurgie je pro běžné lidi, nejen pro celebrity"),
        imgPath: claimPath2,
      },
      {
        text: t("Vybíráme z vašich příběhů a pomáháme uskutečnit váš sen"),
        imgPath: claimPath3,
      },
    ]

    const event = events[0]

    return (
      <>
        <SEO
          title={t("foundation - meta title")}
          description={t("foundation - meta desc")}
          pathname={pathname}
        />
        <Gap.Top />

        <Row responsive>
          <Col maxWidth="586px" grow="1" shrink="5">
            <Title.PageTitle>Nadace Formé Clinic</Title.PageTitle>
            <Gap gap="6px" mobileGap="16px" />
            <Text.Intro>
              <T>Pomáháme běžným lidem</T>
            </Text.Intro>
            <Gap gap="36px" mobileGap="32px" />
            <Text responsive>
              <T>
                Myšlenka nadačního fondu vznikla v říjnu roku 2017 s cílem
                poskytnout všestrannou chirurgickou a poradenskou pomoc a
                přiblížit činnost kliniky běžným lidem. Plastická chirurgie není
                jen pro bohaté a pro celebrity, ale pro všechny, kteří potřebují
                vyřešit nějaký problém. Finanční prostředky z výtěžků nadačních
                akcí, příspěvky partnerů i drobných dárců jsou věnovány
                především na osvětovou činnost, na nákup implantátů a na pomoc
                klientům, kteří operaci potřebují, ale nemůžou si ji dovolit.
              </T>
            </Text>
            <Gap gap="100px" mobileGap="48px" bp={660} />
          </Col>

          <Gap.Fluid desktop min="32px" max="137px" />

          <Col width="100%" maxWidth="416px" shrink="15" showAfter={870}>
            <ResponsiveImage desktop image={introImage.image.gatsbyImageData} />
          </Col>
        </Row>

        <Col
          maxWidth="1010px"
          alignSelf="center"
          alignItems="flex-start"
          width="100%"
        >
          <Row
            justifyContent="stretch"
            width="100%"
            maxWidth="700px"
            responsive
            bp={660}
            padding="0"
            mobilePadding="0 0 0 30px"
          >
            {claims.map((c, index) => (
              <Fragment key={index}>
                {index !== 0 && <Gap.Fluid min="10px" max="56px" />}
                <Col maxWidth="196px">
                  <div>
                    <img src={c.imgPath} alt="claim icon" />
                  </div>
                  <Gap gap="16px" />
                  <Text.Small mobileFontSize="18px" mobileLineHeight="30px">
                    {c.text}
                  </Text.Small>
                  <Gap gap="32px" />
                </Col>
              </Fragment>
            ))}
            {/* ends with 32px bottom gap */}
          </Row>
          <Gap gap="48px" mobileGap="16px" />
          <PostsListing
            posts={firstPosts}
            title="Příběhy lidí, kterým nadace zdarma pomohla"
          />
          {/* has 40px margin */}
          <Gap gap="16px" mobileGap="8px" />

          {event && (
            <Col>
              <Row>
                <Gap gap="115px" onlyWidth desktop />
                <Col>
                  <WaveImage />
                  <Gap gap="32px" mobileGap="24px" />
                </Col>
              </Row>
              <Row alignItems="flex-start" responsive>
                <img src={calendarPath} alt="calendar icon" />
                <Gap gap="43px" mobileGap="24px" />
                <Col>
                  <Title.MediumTitle>{event.title}</Title.MediumTitle>
                  <Gap gap="8px" />
                  <Text.Italic responsive>
                    {moment(event.when).format("D. M. YYY")}
                  </Text.Italic>
                  <Text.Italic responsive>{event.where}</Text.Italic>
                  <Gap gap="16px" />
                  <Text responsive maxWidth="650px">
                    {event.perex}
                  </Text>
                </Col>
              </Row>
            </Col>
          )}

          <Gap gap="80px" mobileGap="48px" />
          <PostsListing posts={afterEventPosts} noWave />
          {limit < posts.length + 1 && (
            <Col alignItems="center" mobileAlignItems="stretch" width="100%">
              <Button.Dashed
                responsiveFullWidth
                onClick={() => {
                  this.setState(state => ({
                    limit: state.limit + 10,
                  }))
                }}
              >
                <T>další příběhy</T>
              </Button.Dashed>
            </Col>
          )}
        </Col>
        <Gap gap="80px" />
        <ContactFormSection />
      </>
    )
  }
}

export default injectIntl(Foundation)

export const pageQuery = graphql`
  query ($language: String!) {
    posts: allPosts(
      sort: { fields: when, order: DESC }
      filter: {
        state: { eq: "published" }
        isOnFoundationPage: { eq: true }
        isFoundationEvent: { ne: true }
        lang: { eq: $language }
      }
    ) {
      edges {
        node {
          ...PostFragment
        }
      }
    }
    events: allPosts(
      sort: { fields: when, order: DESC }
      filter: {
        state: { eq: "published" }
        isFoundationEvent: { eq: true }
        lang: { eq: $language }
      }
      limit: 1
    ) {
      edges {
        node {
          ...PostFragment
        }
      }
    }
    introImage: file(relativePath: { eq: "images/foundation-intro.png" }) {
      image: childImageSharp {
        gatsbyImageData(
          width: 420
          quality: 100
          placeholder: NONE
          layout: CONSTRAINED
        )
      }
    }
  }
`
