import React, { Component } from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import { addResponsivity, up, down, between } from "../lib/styles"
import {
  Col,
  Row,
  Text,
  Title,
  Gap,
  RoundedImage,
  Link,
  WaveImage,
  Listing,
} from "./"
import T from "../lib/intl/T"
import PostItem from "./PostItem"

class PostsListing extends Component {
  render() {
    const { posts, title, noWave } = this.props

    if (!posts || posts.length === 0) {
      return null
    }

    return (
      <>
        {!noWave && <WaveImage />}
        {title ? (
          <>
            <Gap gap="24px" />
            <Title.MediumTitle>{title}</Title.MediumTitle>
            <Gap gap="38px" mobileGap="32px" />
          </>
        ) : (
          !noWave && <Gap gap="40px" mobileGap="24px" />
        )}
        <Listing maxWidth="1010px" gap="98px">
          {posts.map(p => (
            <PostItem innerKey={p._id} key={p._id} post={p} />
          ))}
        </Listing>
      </>
    )
  }
}

PostsListing.propTypes = {}

export default PostsListing
