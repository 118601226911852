import React, { Component } from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import { addResponsivity, up, down, between } from "../lib/styles"
import { Col, Row, Text, Title, Gap, RoundedImage, Link } from "./"

class PostItem extends Component {
  render() {
    const { post, big } = this.props
    const to = `${Link.BLOG}/${post.slug}`

    return (
      <Col maxWidth={big ? "710px" : "456px"} width="100%">
        <Link to={to}>
          <RoundedImage image={post.postImage.filename.main.gatsbyImageData} />
        </Link>
        <Gap gap={big ? "24px" : "12px"} mobileGap="12px" />
        <Link to={to}>
          {big ? (
            <Title.BigTitle as="h2">{post.title}</Title.BigTitle>
          ) : (
            <Title.SmallTitle as="h2">{post.title}</Title.SmallTitle>
          )}
        </Link>
        <Gap gap="8px" />
        <Text responsive maxWidth="555px">
          {post.perex}
        </Text>
        <Gap gap="40px" mobileGap={big ? "24px" : "40px"} />
      </Col>
    )
  }
}

PostItem.propTypes = {}

export default PostItem
